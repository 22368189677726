<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            Schedule a meeting with us to build your security strategy
          </h2>
        </b-link>
        <b-card-text class="mb-2">
          <process phase="Strategy" />
        </b-card-text>
        <b-card-text class="mb-2">
          <vue-calendly url="https://calendly.com/saasment-experts/30min" :height="1000"></vue-calendly>
        </b-card-text>
      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import {
  BCard, BLink, BCardText,
} from 'bootstrap-vue'
import Process from './Process.vue'

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BCardText,
    Process,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
